import { CalendarContext } from '../../../../utils/context/contextFactory';
import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';

export type OnLoginClick = () => void;

export function createOnLoginClickAction({
  context: {
    flowAPI: { experiments, environment },
    wixSdkAdapter,
  },
}: ActionFactoryParams<CalendarState, CalendarContext>): OnLoginClick {
  return () => {
    if (
      !(experiments.enabled('specs.bookings.useFlowApiEnvironmentOverSDK')
        ? environment.isPreview
        : wixSdkAdapter.isPreviewMode())
    ) {
      wixSdkAdapter.promptUserLogin();
    }
  };
}
