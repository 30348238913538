import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { navigateToMembersArea } from '@wix/bookings-booking-navigation';

export type NavigateToMembersArea = () => void;

export function createNavigateToMembersAreaAction({
  context,
}: ActionFactoryParams<CalendarState, CalendarContext>): NavigateToMembersArea {
  return async () => {
    if (
      context.experiments.enabled(
        'specs.bookings.navigationFromNewModule',
      )
    ) {
      navigateToMembersArea(context.flowAPI.controllerConfig.wixCodeApi);
    } else {
      context.wixSdkAdapter.navigateToMembersArea();
    }
  };
}
