import {
  BookingsQueryParams,
  WixOOISDKAdapter,
} from '@wix/bookings-adapter-ooi-wix-sdk';

export const extractQueryParamValues = (
  wixSdkAdapter: WixOOISDKAdapter,
  queryParam: BookingsQueryParams,
): string[] => {
  const queryParamValues = wixSdkAdapter.getUrlQueryParamValue(queryParam);

  if (queryParamValues) {
    const queryParamValuesArray = queryParamValues.split(',');
    return queryParamValuesArray.map((value) => value.trim());
  }

  return [];
};
